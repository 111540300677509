import React, { useEffect, useState } from "react";
import { HomeAndConstructionBusiness, WithContext } from "schema-dts";

import { JSONLDComponent } from "../JSONLDComponent";

import { openingHoursMap } from "./utils";

import { useOmnichannelApi } from "~/OmnichannelApi";

export const LocalBusinessSchema: React.FC = () => {
  const client = useOmnichannelApi();

  const [locationData, setLocationData] =
    useState<WithContext<HomeAndConstructionBusiness>>();

  useEffect(() => {
    const matches = window.location.pathname.match(/^\/locations\/([^/]+)/i);

    if (matches) {
      const [, locationId] = matches;
      client["location-slugs.get"](locationId).then(({ data }) => {
        const structuredData: WithContext<HomeAndConstructionBusiness> = {
          "@context": "https://schema.org",
          "@type": "HomeAndConstructionBusiness",
          name: data.name,
          description: data.description,
          branchCode: String(data.location_id || ""),
          url: window.location.origin + window.location.pathname,
          address: {
            "@type": "PostalAddress",
            streetAddress: data.location_address,
            addressLocality: data.city,
            addressRegion: data.state,
            postalCode: data.postal_code,
            addressCountry: "US",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: data.latitude,
            longitude: data.longitude,
          },
          telephone: data.location_phone,
          //hasMap: data.get_directions_link,
          openingHoursSpecification: openingHoursMap(data),
          hasOfferCatalog: {
            "@type": "OfferCatalog",
            name: "Rental Services",
            itemListElement: data.location_services?.map((service) => {
              return {
                "@type": "Offer",
                itemOffered: {
                  "@type": "Service",
                  name: service.rental_service_name,
                  description: service.rental_service_rich_text_content,
                },
                areaServed: {
                  "@type": "GeoCircle",
                  geoMidpoint: {
                    "@type": "GeoCoordinates",
                    latitude: data.latitude,
                    longitude: data.longitude,
                  },
                  geoRadius: data.delivery_radius_miles,
                },
              };
            }),
          },
        };

        setLocationData(structuredData);
      });
    }
  }, []);

  if (!locationData) {
    return null;
  }

  return <JSONLDComponent structuredData={locationData} />;
};
