import React, { useEffect, useState } from "react";
import { Article, WithContext } from "schema-dts";

import { JSONLDComponent } from "../JSONLDComponent";

import { useOmnichannelApi } from "~/OmnichannelApi";

export const ArticleSchema: React.FC = () => {
  const client = useOmnichannelApi();

  const [articleData, setArticleData] = useState<WithContext<Article>>();

  useEffect(() => {
    const matches = window.location.pathname.match(/^\/articles\/([^/]+)/i);

    if (matches) {
      const [, articleSlug] = matches;

      client["webflow.collections.items.get"]({
        item_slug: articleSlug,
        collection_slug: "articles",
      }).then(({ data }) => {
        const blogPost = data as unknown as Record<string, string> & {
          "main-image": { url: string };
        };

        const structuredData: WithContext<Article> = {
          "@context": "https://schema.org",
          "@type": "Article",
          headline: blogPost["name"],
          datePublished: blogPost["published-on"],
          dateModified: blogPost["updated-on"],
          author: blogPost["post-author"],
          articleBody: `${blogPost["section-1-body"]}
						${blogPost["section-2-body"]}
						${blogPost["section-3-body"]}
						${blogPost["section-4-body"]}
						${blogPost["section-5-body"]}
						${blogPost["conclusion-body"]}`,
          url: window.location.origin + window.location.pathname,
        };

        const image = blogPost["main-image"]?.url;
        if (image) {
          structuredData.image = image;
        }

        setArticleData(structuredData);
      });
    }
  }, []);

  if (!articleData) {
    return null;
  }

  return <JSONLDComponent structuredData={articleData} />;
};
