import React, { useEffect, useState } from "react";
import { AggregateOffer, Country, WithContext } from "schema-dts";

import { JSONLDComponent } from "../JSONLDComponent";

import { useOmnichannelApi } from "~/OmnichannelApi";

export const AggregateOfferSchema: React.FC = () => {
  const client = useOmnichannelApi();

  const [aggregateOfferData, setAggregateOfferData] =
    useState<WithContext<AggregateOffer>>();

  useEffect(() => {
    const matches = window.location.pathname.match(
      /^\/rent\/categories\/([^/]+)/i
    );

    if (matches) {
      const [, categorySlug] = matches;

      client["webflow.collections.items.get"]({
        item_slug: categorySlug,
        collection_slug: "categories",
      }).then(({ data }) => {
        const category = data as unknown as Record<string, string> & {
          "primary-image": { fieldId: string; url: string; alt: string };
        };

        const areaServed: Country = {
          "@type": "Country",
          name: "US",
        };

        const structuredData: WithContext<AggregateOffer> = {
          "@context": "https://schema.org",
          "@type": "AggregateOffer",
          name: category.name,
          description: category.description,
          image: category["primary-image"]?.url,
          url: window.location.origin + window.location.pathname,
          areaServed,
        };

        setAggregateOfferData(structuredData);
      });
    }
  }, []);

  if (!aggregateOfferData) {
    return null;
  }

  return <JSONLDComponent structuredData={aggregateOfferData} />;
};
