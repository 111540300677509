/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jest/require-hook */

import { useOmnichannelApi } from "./OmnichannelApi";

window.getGeo = async () => {
  const SESSION_STORAGE_KEY = "_geo";

  const geo = sessionStorage.getItem(SESSION_STORAGE_KEY);

  if (geo) {
    return JSON.parse(geo);
  }

  const client = useOmnichannelApi();

  const { data } = await client.geolocate();

  if (data) {
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(data));

    return data;
  }
};

window.getGeo();
