import { DayOfWeek, OpeningHoursSpecification } from "schema-dts";

import { Paths } from "~/OmnichannelApi/openapi";
import { isTruthy } from "~/utils";
export const makeHoursObject = (
  dayOfWeek: DayOfWeek,
  hours: string
): OpeningHoursSpecification | undefined => {
  if (/^closed$/i.test(hours.trim())) {
    return;
  }

  const terms = hours.split(" - ");

  return {
    "@type": "OpeningHoursSpecification",
    opens: convertTime(terms[0])!,
    closes: convertTime(terms[1])!,
    dayOfWeek: dayOfWeek,
  };
};

export const convertTime = (timeString: string) => {
  const regex = /^(\d+):(\d+)(AM|PM)$/i;
  const timeTerms = timeString.match(regex);
  if (timeTerms?.length !== 4) {
    throw new Error(
      `${timeString} timeString is not properly formatted for json+ld!`
    );
  }

  const hr = timeTerms[1].trim();
  const min = timeTerms[2].trim();
  const period = timeTerms[3].trim();
  if (period === "AM") {
    return [parseInt(hr) < 10 ? `0${hr}` : hr, min].join("");
  } else if (period === "PM") {
    return [Number(hr) + 12, min].join("");
  }
};

export const openingHoursMap = (
  data: Paths.LocationSlugsGet.Responses.$200
): Array<OpeningHoursSpecification> => {
  return (
    [
      ["Monday", data.hours_of_operation_monday],
      ["Tuesday", data.hours_of_operation_tuesday],
      ["Wednesday", data.hours_of_operation_wednesday],
      ["Thursday", data.hours_of_operation_thursday],
      ["Friday", data.hours_of_operation_friday],
      ["Saturday", data.hours_of_operation_saturday],
      ["Sunday", data.hours_of_operation_sunday],
    ] as Array<[DayOfWeek, string]>
  )
    .map((day) => makeHoursObject(...day))
    .filter(isTruthy);
};
