import React from "react";
import { createRoot } from "react-dom/client";
import { Helmet } from "react-helmet";

import { AggregateOfferSchema } from "./AggregateOfferSchema";
import { ArticleSchema } from "./ArticleSchema";
import { BlogPostSchema } from "./BlogPostSchema";
import { BreadcrumbListSchema } from "./BreadcrumbListSchema";
import { LocalBusinessSchema } from "./LocalBusinessSchema/index";
import { makeCanonicalUrl } from "./makeCanonicalUrl";
import { OfferForLeaseSchema } from "./OfferForLeaseSchema";
import { PressSchema } from "./PressSchema";
import { WebSiteSchema } from "./WebSiteSchema";

import { isAppletPicker, isDevelopment } from "~/env";

const Applet: React.FC = () => {
  if (
    !isDevelopment &&
    !["www.equipmentshare.com", "equipmentshare-us.webflow.io"].includes(
      window.location.hostname
    )
  ) {
    return null;
  }

  return (
    <React.Fragment>
      <Helmet>
        <link
          rel="canonical"
          href={
            window.location.origin +
            makeCanonicalUrl(new URL(window.location.href))
          }
        />
      </Helmet>
      <PressSchema />
      <WebSiteSchema />
      <BreadcrumbListSchema />
      <BlogPostSchema />
      <ArticleSchema />
      {/* JobPostings are handled by JobPosting applet */}
      <AggregateOfferSchema />
      <OfferForLeaseSchema />
      <LocalBusinessSchema />
    </React.Fragment>
  );
};

export const mount = async (el: HTMLDivElement) => {
  if (isDevelopment || isAppletPicker) {
    const fixtures = await import("./fixtures");

    const elBreadcrumbs = document.createElement("div");
    elBreadcrumbs.innerHTML = fixtures.BREADCRUMBS;
    document.body.appendChild(elBreadcrumbs);
  }

  const root = createRoot(el);
  root.render(<Applet />);
};
