import React, { useEffect, useState } from "react";
import { Product, WithContext } from "schema-dts";

import {
  RENTAL_DAILY_OFFER,
  RENTAL_MONTHLY_OFFER,
  RENTAL_WEEKLY_OFFER,
} from "../constants";
import { JSONLDComponent } from "../JSONLDComponent";

import { useOmnichannelApi } from "~/OmnichannelApi";

export const OfferForLeaseSchema: React.FC = () => {
  const client = useOmnichannelApi();

  const [productData, setProductData] = useState<WithContext<Product>>();

  useEffect(() => {
    const matches = window.location.pathname.match(
      /^\/rent\/equipment-classes\/([^/]+)/i
    );

    if (matches) {
      const [, equipmentClassSlug] = matches;

      client["webflow.collections.items.get"]({
        item_slug: equipmentClassSlug,
        collection_slug: "equipment-classes",
      }).then(({ data }) => {
        const product = data as unknown as Record<string, string> & {
          images: Array<{ fieldId: string; url: string; alt: string }>;
          "primary-image": { fieldId: string; url: string; alt: string };
        };

        const structuredData: WithContext<Product> = {
          "@context": "https://schema.org",
          "@type": "Product",
          name: product.name,
          description: product.description,
          image: product["primary-image"]?.url,
          url: window.location.origin + window.location.pathname,
        };

        setProductData(structuredData);
      });
    }
  }, []);

  if (!productData) {
    return null;
  }

  return (
    <React.Fragment>
      {[RENTAL_DAILY_OFFER, RENTAL_WEEKLY_OFFER, RENTAL_MONTHLY_OFFER].map(
        (offer, idx) => (
          <JSONLDComponent
            key={idx}
            structuredData={{
              ...offer,
              itemOffered: productData,
            }}
          />
        )
      )}
    </React.Fragment>
  );
};
